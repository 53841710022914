.footer-section{
    display: grid;
    justify-content: center;
    align-content: end;
    min-height: 100vh;
    background: #e8e8e8;
    padding: 50px;
}

footer{
    position: relative;
    width: 100%;
    height: auto;
    padding: 50px 100px;
    background: #FFC94A;
}

footer .footer-container{
    width: 100%;
    display: grid;
    grid-template-columns: 2fr 1fr 1fr 1fr;
    gap: 20px;
}

footer .footer-container .footer-sec h2{
    position: relative;
    color: #fff;
    font-weight: 500;
    font-size: 1.8rem;
    margin-bottom: 15px;
}

footer .footer-container .footer-sec p{
    color: #fff;
    text-align: justify;
}

footer .footer-container .footer-sci{
    margin-top: 20px;
    display: grid;
    grid-template-columns: repeat(4,50px);
}

footer .footer-container .footer-sci li{
    list-style: none;
}

footer .footer-container .footer-sci li a{
    display: inline-block;
    width: 36px;
    height: 36px;
    background: #fff;
    display: grid;
    align-content: center;
    justify-content: center;
    text-decoration: none;
}

footer .footer-container .footer-sci li a i{
    color: #fff;
    font-size: 20px;
}

footer .footer-container .footer-quicklinks{
    position: relative;
}

footer .footer-container .footer-quicklinks ul li{
    list-style: none;
}

footer .footer-container .footer-quicklinks ul li a{
    color: #fff;
    text-decoration: none;
    margin-bottom: 10px;
    display: inline-block;
}

footer .footer-container .footer-contact .info{
    position: relative;
}

footer .footer-container .footer-contact .info li{
    display: grid;
    grid-template-columns: 30px 1fr;
    margin-bottom: 16px;
}

footer .footer-container .footer-contact .info li span{
    color: #fff;
    font-size: 20px;
}

footer .footer-container .footer-contact .info li a{
    color: #fff;
    text-decoration: none;
}

.copyrightText{
    width: 100%;
    background: #FF9800;
    padding: 20px 100px 30px;
    text-align: center;
    color: #fff;
    border: 1px solid rgba(0, 0, 0, 0.15);
}

@media (max-width: 991px){
    footer{
        padding: 40px;
    }
    footer .footer-container{
        width: 100%;
        display: grid;
        grid-template-columns: repeat(2,1fr);
        gap: 20px;
    }
    .copyrightText{
        padding: 20px 40px 30px;
    }
}
@media (max-width: 768px){
    footer .footer-container{
        width: 100%;
        display: grid;
        grid-template-columns: repeat(1,1fr);
        gap: 20px;
    }
}